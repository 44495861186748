import Container from '@mui/material/Container';
import DocumentDetails from '../../components/documents/DocumentDetails';
import { Box } from '@mui/material';
const DocumentDetailsPage = () => {
  return (
    <Container maxWidth={false}>
      <Box sx={{ mt: 10 }}>
        <DocumentDetails />
      </Box>
    </Container>
  );
};

export default DocumentDetailsPage;
