import Container from '@mui/material/Container';
import TaskTab from '../../components/tasks/Tasks';
const TaskPage = () => {
  return (
    <Container maxWidth={false}>
      <TaskTab />
    </Container>
  );
};

export default TaskPage;
