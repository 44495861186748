import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import EnterPassword from './EnterPassword';
import MobileOtp from './MobileOtp';

import { showSnackbarAction } from '../../redux/alert/alertAction';
import api from '../../utility/api';
import { useNavigate } from 'react-router-dom';

const validEmail = /\S+@\S+\.\S+/;
const validPhone = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

var reg = /^[0-9]{1,10}$/;

const EmailOrMobile = () => {
  const [countryCodes, setCountryCodes] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [emailOrPhoneVal, setEmailOrPhoneVal] = useState('');
  const [isPhone, setIsPhone] = useState(false);
  const [step1, setStep1] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    emailOrPhone: emailOrPhoneVal,
    countryCode: '',
  };
  const onSubmit = async (values, actions) => {
    const formData = {
      email: !isPhone ? emailOrPhoneVal : '',
      mobile: isPhone ? emailOrPhoneVal : '',
      countryCode: values.countryCode.value,
    };

    if (!isPhone) {
      delete formData.countryCode;
      delete formData.mobile;
    }

    if (isPhone) {
      delete formData.email;
    }

    try {
      const response = await api.post(`/auth/clients/login/step1`, formData);
      if (response) {
        setStep1(response.data.data);
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  // function isValidEmail(email) {
  //   return /\S+@\S+\.\S+/.test(email);
  // }

  // function isValidPhone(phone) {
  //   return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(phone);
  // }

  // const handleChange = (e) => {
  //   if (!isValidEmail(e.target.value) || isValidPhone(e.target.value)) {
  //     setError("Email is invalid");
  //   } else {
  //     setEmailOrPhoneVal(e.target.value);
  //     setIsPhone(!isNaN(+e.target.value));
  //     setError(null);
  //   }

  //   setMessage(e.target.value);
  // };

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data?.data.countries);
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 15,
        }}
      >
        <Typography variant="h3" textAlign="center">
          Welcome to Attorney
        </Typography>
        <Paper sx={{ width: '600px', p: 6, mt: 6 }}>
          {step1.length === 0 ? (
            <>
              <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    required={true}
                    id="emailOrPhone"
                    name="emailOrPhone"
                    label="Email or Mobile"
                    variant="outlined"
                    value={emailOrPhoneVal}
                    onChange={(e) => {
                      setEmailOrPhoneVal(e.target.value);
                      setIsPhone(reg.test(e.target.value));
                    }}
                  />
                  {isPhone && emailOrPhoneVal.length > 10 ? (
                    <Typography color="red">
                      Mobile must be 10 digits
                    </Typography>
                  ) : null}
                  {isPhone && (
                    <Autocomplete
                      value={formik.values?.countryCode}
                      id="countryCode"
                      name="countryCode"
                      options={countryCodes}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      }
                      onChange={(event, newValue) => {
                        formik.setFieldValue('countryCode', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="CountryCode"
                          error={
                            formik.touched.countryCode &&
                            Boolean(formik.errors.countryCode)
                          }
                          helperText={
                            formik.touched.countryCode &&
                            formik.errors.countryCode
                          }
                        />
                      )}
                    />
                  )}
                  <Button
                    variant="contained"
                    sx={{ mt: 1, mr: 1, color: 'white', bgcolor: '#0070BB' }}
                    type="submit"
                  >
                    Continue
                  </Button>
                </Stack>
              </form>
              <Box>
                <Typography
                  textAlign="end"
                  sx={{ cursor: 'pointer', color: '#0070BB', mt: 2 }}
                  onClick={() => navigate('/forgot-password')}
                >
                  Forgot Password?
                </Typography>
              </Box>
            </>
          ) : step1.userRegistrationStatus ? (
            <EnterPassword step1={step1} />
          ) : (
            <MobileOtp step1={step1} setStep={() => setStep1('')} />
          )}
        </Paper>
      </Box>
    </>
  );
};

export default EmailOrMobile;
