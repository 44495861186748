import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { ArrowBack } from '@mui/icons-material';

const BillsList = () => {
  const { state } = useLocation();

  let [documents, setDocuments] = useState();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllCaseDocuments = async (caseId, userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/firms/${firmId}/cases/${caseId}/documents?type=bill`
      );
      setDocuments(response.data.data.allDocuments);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCaseDocuments(
      state?.caseId,
      user.userProfiles[0]?._id,
      user.userProfiles[0]?.firm?._id
    );
  }, [user.userProfiles[0]?._id]);

  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: documents?.length > 0 ? 1 : 0,
        bgcolor: documents?.length > 0 ? 'background.paper' : '',
        mt: 10,
      }}
    >
      <nav aria-label="main mailbox folders">
        <Box
          sx={{
            bgcolor: '#F7F7F7',
            display: 'flex',
            justifyContent: 'centre',
            borderBottom: '1px solid #cbcccf',
            padding: '1rem',
            boxShadow: '0 1px #efefef',
          }}
        >
          <ArrowBack
            sx={{ cursor: 'pointer', mr: 2, mt: 0.2 }}
            onClick={() => navigate(-1)}
          />
          <Typography
            variant="h5"
            sx={{
              alignItems: 'center',
              fontSize: '18px',
              fontWeight: 700,
            }}
          >
            {state?.caseName}
          </Typography>
        </Box>
        {/* <Typography
          variant="h5"
          sx={{
            alignItems: 'center',
            fontSize: '18px',
          }}
        >
          Billing History
        </Typography> */}
        <List>
          {documents &&
            documents?.map((d, i) => {
              return (
                <Box key={d._id}>
                  <ListItem
                    secondaryAction={
                      <Typography
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                        variant="p"
                      >
                        {new Date(d.updatedAt).toDateString()}
                      </Typography>
                    }
                    onClick={() =>
                      navigate('/documents/document-details', {
                        state: {
                          documentId: d._id,
                          caseId: state?.caseId,
                          type: 'bill',
                        },
                      })
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DescriptionIcon sx={{ color: '#616161' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={d.name}
                        sx={{ overflow: 'hidden' }}
                        secondary={d.document.fileNameWithExtension}
                      />
                    </ListItemButton>
                  </ListItem>
                  {i + 1 == documents.length ? null : <Divider />}
                </Box>
              );
            })}

          {documents?.length === 0 && (
            <Typography
              sx={{
                textAlign: 'center',
                fontStyle: 'italic',
                fontSize: '18px',
                mt: 4,
                colorl: '#212529',
              }}
            >
              No Invoices or Funds Requests
            </Typography>
          )}
        </List>
      </nav>
    </Box>
  );
};

export default BillsList;
