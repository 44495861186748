import Container from '@mui/material/Container';
import DocumentDetails from '../../components/documents/DocumentDetails';
import DocumentList from '../../components/documents/DocumentList';

let documentList = [
  {
    _id: 3,
    name: 'wasim.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 4,
    name: 'vinaya.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 5,
    name: 'kb.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 6,
    name: 'doc.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 7,
    name: 'docccccccccccccccccccccccccccccccccccccccc.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 8,
    name: 'marksheet.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 9,
    name: 'aadhard.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 10,
    name: 'license.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
];
const FolderDetailsPage = () => {
  return (
    <Container sx={{ mt: 10 }} maxWidth="xl">
      <DocumentList data={documentList} />
    </Container>
  );
};

export default FolderDetailsPage;
