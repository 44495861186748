import Container from '@mui/material/Container';
import TaskDetails from '../../components/tasks/TaskDetails';

const TaskDetailsPage = () => {
  return (
    <Container maxWidth={false}>
      <TaskDetails />
    </Container>
  );
};

export default TaskDetailsPage;
