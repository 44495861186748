import React, { Fragment, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import HomePage from './pages/home-page/HomePage';
import Navbar from './components/navbar/Navbar';
import DocumentsPage from './pages/documents-page/DocumentsPage';
import MyProfile from './components/profile/Profile';
import DocumentDetailsPage from './pages/ducementDetails-page/DocumentDetailsPage';
import FolderDetailsPage from './pages/file-page/FolderDetailsPage';
import PrivateRoutes from './components/routing/PrivateRoutes';
import RegisterPage from './pages/register-page/RegisterPage';
import ProfilePage from './pages/profile-page/ProfilePage';
import ResetPasswordPage from './pages/resetPassword-page/ResetPasswordPage';
import ForgotPasswordPage from './pages/forgot-password-page/ForgotPasswordPage';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import { SnackbarComp } from './components/snackbar/Snackbar';
import CaseDocumentsPage from './pages/documents-page/CaseDocumentsPage';
import { useDispatch } from 'react-redux';
import setAuthToken from './utility/setAuthToken';
import { loadUserAction } from './redux/auth/authAction';
import CasesPage from './pages/CasePage/CasePage';
import BillsPage from './pages/BillsPage/BillsPage';
import TaskPage from './pages/task-page/TaskPage';
import TaskDetailsPage from './pages/task-page/TaskDetailsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsPage from './pages/TermsPage/TermsPage';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    setAuthToken(localStorage.token);
    dispatch(loadUserAction());
  }, []);
  return (
    <PersistGate persistor={persistor}>
      <Fragment>
        <SnackbarComp />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<RegisterPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route
              path="/"
              element={
                <PrivateRoutes>
                  <DashboardPage />
                </PrivateRoutes>
              }
            >
              <Route path="/home" element={<HomePage />} />
              <Route path="/documents" element={<DocumentsPage />} />
              <Route
                path="/documents/case-documents"
                element={<CaseDocumentsPage />}
              />
              <Route path="/cases/bills" element={<BillsPage />} />
              <Route path="/cases" element={<CasesPage />} />
              <Route
                path="/documents/document-details"
                element={<DocumentDetailsPage />}
              />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
              <Route path="/folder-details" element={<FolderDetailsPage />} />
              <Route path="/settings" element={<ProfilePage />} />
              <Route path="/tasks" element={<TaskPage />} />
              <Route path="/tasks/task-details" element={<TaskDetailsPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Fragment>
    </PersistGate>
  );
}

export default App;
