import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import AddDocument from './AddDocument';
import {
  loadAddDocumentAction,
  loadDocumentListAction,
} from '../../redux/document/documentAction';
import Loader from '../loader/Loader';

const CaseDocuments = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  // let [documents, setDocuments] = useState();
  let [caseDetails, setCase] = useState();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.document?.documentList);

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const getAllCaseDocuments = async (caseId, userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/firms/${firmId}/cases/${caseId}/documents`
      );

      dispatch(loadDocumentListAction(response.data.data.allDocuments));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const getCaseDetails = async (caseId) => {
    try {
      setIsLoading(true);
      const response = await api.get(`/cases/${caseId}`);
      setIsLoading(false);
      setCase(response.data.data.case);
    } catch (error) {
      setIsLoading(false);
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCaseDocuments(
      state?.caseId ? state?.caseId : props?.caseId,
      user.userProfiles[0]?._id,
      user.userProfiles[0]?.firm?._id
    );
    getCaseDetails(state?.caseId ? state?.caseId : props?.caseId);
  }, [user.userProfiles[0]?._id]);

  return (
    <>
      <Box
        sx={{
          mt: 10,
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            // alignItems="end"
            spacing={2}
          >
            <Typography variant="h6" component="h6">
              {caseDetails?.caseName}
            </Typography>
            <Button
              variant="contained"
              sx={{ bgcolor: '#0070BB' }}
              onClick={() => handleClose()}
            >
              UPLOAD DOCUMENT
            </Button>
            {showModal && (
              <AddDocument
                showModal={showModal}
                handleClose={handleClose}
                case_id={caseDetails?._id}
              />
            )}
          </Stack>
        )}
        {documents?.length > 0 && (
          <Box
            sx={{
              width: '100%',
              boxShadow: 1,
              bgcolor: 'background.paper',
              mt: 2,
            }}
          >
            <nav aria-label="main mailbox folders">
              <List>
                {documents &&
                  documents?.map((d, i) => {
                    return (
                      <Box key={d._id}>
                        <ListItem
                          secondaryAction={
                            <Typography
                              sx={{ display: { xs: 'none', sm: 'block' } }}
                              variant="p"
                            >
                              {new Date(d.updatedAt).toDateString()}
                            </Typography>
                          }
                          onClick={() =>
                            navigate('/documents/document-details', {
                              state: {
                                documentId: d._id,
                                caseId: state?.caseId
                                  ? state?.caseId
                                  : props?.caseId,
                              },
                            })
                          }
                          disablePadding
                        >
                          <ListItemButton>
                            <ListItemIcon>
                              <DescriptionIcon sx={{ color: '#616161' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={d.name}
                              sx={{ overflow: 'hidden' }}
                              secondary={d.document.fileNameWithExtension}
                            />
                          </ListItemButton>
                        </ListItem>
                        {i + 1 == documents.length ? null : <Divider />}
                      </Box>
                    );
                  })}
              </List>
            </nav>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CaseDocuments;
