import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';
import AddDocument from '../documents/AddDocument';
import api from '../../utility/api';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadRecentActivityListAction } from '../../redux/recentActivity/recentActivityAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([1, 2, 3, 4]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = () => {};

  const user = useSelector((state) => state?.auth?.user);
  const recentDocuments = useSelector(
    (state) => state?.recentActivity?.recentActivityList
  );

  const handlClose = () => {
    setShowModal(!showModal);
  };
  const getRecentActivities = async (userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/firms/${firmId}/activities?limit=4`
      );

      dispatch(loadRecentActivityListAction(response.data.data.documents));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getRecentActivities(
      user.userProfiles[0]?._id,
      user.userProfiles[0]?.firm?._id
    );
  }, [user.userProfiles[0]?._id]);

  return (
    <>
      <Stack
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {/* <Box sx={{ width: '150px', textAlignLast: 'center' }}>
          <Avatar
            sx={{
              bgcolor: '#03a9f4',
              padding: 2,
              marginLeft: 4,
              marginBottom: 1,
              ':hover': {
                bgcolor: '#0288d1',
                cursor: 'pointer',
              },
            }}
          >
            <EmailIcon />
          </Avatar>
          <Typography>send message</Typography>
        </Box> */}
        <Box
          sx={{ width: '150px', textAlignLast: 'center' }}
          onClick={() => handlClose()}
        >
          <Avatar
            sx={{
              backgroundColor: '#0070BB',
              padding: 2,
              marginLeft: 4,
              marginBottom: 1,
              ':hover': {
                bgcolor: '#0288d1',
                cursor: 'pointer',
              },
              height: '45px',
              width: '45px',
            }}
            sizes="large"
          >
            <ArticleIcon sx={{ fontSize: '30px' }} />
          </Avatar>
          <Typography>Add document</Typography>
        </Box>
        <Box sx={{ width: '150px', textAlignLast: 'center' }}>
          <Avatar
            sx={{
              backgroundColor: '#0070BB',
              padding: 2,
              marginLeft: 4,
              marginBottom: 1,
              ':hover': {
                bgcolor: '#0288d1',
                cursor: 'pointer',
              },
              height: '45px',
              width: '45px',
            }}
            onClick={() => navigate('/cases')}
            // sizes="xl"
          >
            <MonetizationOnIcon sx={{ fontSize: '30px' }} />
          </Avatar>
          <Typography>View Bills</Typography>
        </Box>
      </Stack>
      {/* <Stack sx={{ height: '200px' }}>
        <Typography textAlign="center" marginY={10} fontStyle="italic">
          No upcoming events
        </Typography>
      </Stack> */}
      <Typography marginBottom={4} marginTop={10}>
        Recent Activity (All Cases)
      </Typography>
      {recentDocuments.length > 0 && (
        <Paper>
          <List>
            {recentDocuments.length > 0 &&
              recentDocuments.map((d, i) => {
                return (
                  <Box key={d._id}>
                    <ListItem
                      secondaryAction={
                        d.type === 'file' && (
                          <Typography
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                            variant="p"
                          >
                            {d.updatedAt}
                          </Typography>
                        )
                      }
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <DescriptionIcon
                            sx={{ color: '#636C72', fontSize: '30px' }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          onClick={() => {
                            navigate('/documents/document-details', {
                              state: {
                                documentId: d._id,
                                caseId: d.case._id,
                              },
                            });
                          }}
                          primary={
                            <Box
                              sx={{
                                mb: 1,
                                fontSize: '17.5px',
                                color: '#212529',
                              }}
                            >
                              <Typography
                                sx={{ color: '#212529' }}
                                variant="span"
                              >
                                {' '}
                                {user.userProfiles[0]?.firstName}{' '}
                                {user.userProfiles[0]?.lastName}{' '}
                                {'added document'}{' '}
                              </Typography>
                              <Typography
                                variant="span"
                                sx={{ color: '#0070BB' }}
                              >
                                {' '}
                                {d.name}
                              </Typography>
                            </Box>
                          }
                          sx={{ overflow: 'hidden' }}
                          secondaryTypographyProps={{ sx: { color: 'green' } }}
                          secondary={`Last updated ${new Date(
                            d.updatedAt
                          ).toDateString()}`}
                        />
                      </ListItemButton>
                    </ListItem>
                    {i + 1 == recentDocuments.length ? null : <Divider />}
                  </Box>
                );
              })}
            {recentDocuments?.length > 0 && (
              <Typography
                textAlign="center"
                color="#0070BB"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate('/documents')}
              >
                View all recent activities
              </Typography>
            )}
          </List>
        </Paper>
      )}

      {showModal && (
        <AddDocument showModal={showModal} handleClose={handlClose} />
      )}
    </>
  );
};

export default Home;
