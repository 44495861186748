import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import CaseDocuments from './CaseDocuments';

const DocumentList = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: props.data && props.data.length === 1 ? 0 : 1,
        bgcolor:
          props.data && props.data.length === 1 ? '' : 'background.paper',
        mt: props.data && props.data.length === 1 ? 0 : 10,
      }}
    >
      <nav aria-label="main mailbox folders">
        <List>
          {props.data && props.data.length === 1 ? (
            <CaseDocuments caseId={props.data[0]._id} />
          ) : (
            props.data &&
            props.data.map((d, i) => {
              return (
                <Box key={d._id}>
                  <ListItem
                    // secondaryAction={
                    //   d.type === 'file' && (
                    //     <Typography
                    //       sx={{ display: { xs: 'none', sm: 'block' } }}
                    //       variant="p"
                    //     >
                    //       {d.updatedAt}
                    //     </Typography>
                    //   )
                    // }
                    // onClick={() =>
                    //   d.type === 'file'
                    //     ? navigate('/document-details')
                    //     : navigate('/folder-details')
                    // }
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() =>
                        navigate('/documents/case-documents', {
                          state: { caseId: d._id },
                        })
                      }
                    >
                      <ListItemIcon>
                        <FolderIcon sx={{ color: '#616161' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={d.caseName}
                        sx={{ overflow: 'hidden' }}
                        // secondary={
                        //   d.type === 'file'
                        //     ? d.name
                        //     : `Last updated ${d.updatedAt}`
                        // }
                      />
                    </ListItemButton>
                  </ListItem>
                  {i + 1 == props.data.length ? null : <Divider />}
                </Box>
              );
            })
          )}
        </List>
      </nav>
    </Box>
  );
};

export default DocumentList;
