import { useState } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ArticleIcon from '@mui/icons-material/Article';
import { useNavigate } from 'react-router-dom';

const drawerMenuOptions = [
  { text: 'HOME', icon: <HomeIcon />, path: '/home' },
  {
    text: 'DOCUMENTS',
    icon: <ArticleIcon />,
    path: '/documents',
  },
  {
    text: 'TASKS',
    icon: <ArticleIcon />,
    path: '/tasks',
  },
  {
    text: 'Bills',
    icon: <ArticleIcon />,
    path: '/cases',
  },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="left"
        transitionDuration={400}
        PaperProps={{
          sx: {
            width: '60%',
            // opacity: "0.5",
            paddingTop: 1,
            paddingLeft: 2,
          },
        }}
      >
        <List>
          {drawerMenuOptions.map((item) => {
            return (
              <ListItemButton
                onClick={() => {
                  navigate(item.path);
                  setOpenDrawer(false);
                }}
              >
                <ListItemIcon sx={{ marginRight: 0 }}>{item.icon}</ListItemIcon>
                <ListItemText>{item.text}</ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>
    </>
  );
};

export default DrawerComp;
