import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Typography,
  Avatar,
  ListItemAvatar,
  Divider,
  ListItem,
  ListItemText,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';

const CaseList = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const dispatch = useDispatch();
  let [cases, setCases] = useState();
  const user = useSelector((state) => state.auth.user);
  const getAllCases = async (userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/cases/firms/${firmId}`
      );
      setCases(response.data.data.case);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCases(user?.userProfiles[0]?._id, user?.userProfiles[0]?.firm?._id);
  }, [user?.userProfiles[0]?._id]);

  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: props.data && props.data.length === 1 ? 0 : 1,
        bgcolor:
          props.data && props.data.length === 1 ? '' : 'background.paper',
        mt: props.data && props.data.length === 1 ? 0 : 10,
      }}
    >
      <nav aria-label="main mailbox folders">
        <List>
          {cases &&
            cases?.map((d, i) => {
              return (
                <Box key={d._id}>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() =>
                        navigate('/cases/bills', {
                          state: { caseId: d._id, caseName: d.caseName },
                        })
                      }
                    >
                      <ListItemIcon>
                        <FolderIcon sx={{ color: '#616161' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={d.caseName}
                        sx={{ overflow: 'hidden' }}
                      />
                    </ListItemButton>
                  </ListItem>
                  {i + 1 == cases?.length ? null : <Divider />}
                </Box>
              );
            })}
        </List>
      </nav>
    </Box>
  );
};

export default CaseList;
