import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { LoadUserProfile, loginAction } from '../../redux/auth/authAction';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import SelectFirm from './SelectFirm';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    )
    .required('Required'),
});

const EnterPassword = ({ step1, step2 }) => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const { secret } = useParams();
  const dispatch = useDispatch();

  const handleShowPassword = () => setShowPassword(!showPassword);
  const initialValues = {
    password: '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      password: values.password,
      userId: step2?.userHasPassword ? step2.userId : step1.userId,
    };

    try {
      const response = await api.post(`/auth/clients/login/password`, formData);

      if (response) {
        dispatch(showSnackbarAction(response.data.message, 'success'));
        console.log('passsword step', response.data.data);
        if (response?.data?.data?.userProfiles?.length === 1) {
          localStorage.setItem('token', response.data.data.token);
          dispatch(loginAction(response.data.data));
          navigate('/home');
        }
        dispatch(LoadUserProfile(response.data.data));
      }
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <>
      {isAuthenticated && user?.userProfiles?.length > 1 ? (
        <SelectFirm />
      ) : (
        <Box>
          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <TextField
                id="password"
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required={true}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button
                variant="contained"
                sx={{ mt: 1, mr: 1, color: 'white', bgcolor: '#0070BB' }}
                type="submit"
              >
                Login
              </Button>
            </Stack>
          </form>
          <Box>
            <Typography
              textAlign="end"
              sx={{ cursor: 'pointer', color: '#0070BB', mt: 2 }}
              onClick={() => navigate('/forgot-password')}
            >
              Forgot Password?
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EnterPassword;
