import api from './api';

// this a function to set a globel header in axios
// It takes the token and sets on axios globel header
// so that with every request we are sending the token in the header

const setAuthToken = (token) => {
  if (token) {
    localStorage.removeItem('token');
    console.log('run auth token');
    api.defaults.headers.common['x-access-token'] = token;
    localStorage.setItem('token', token);
  } else {
    delete api.defaults.headers.common['x-access-token'];
    localStorage.removeItem('token');
  }
};

export default setAuthToken;
