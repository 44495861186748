import { GET_ALL_DOCUMENT, ADD_DOCUMENT } from './documentTypes';

const INITIAL_STATE = {
  documentList: [],
  addDocument: {},
};

const documentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_DOCUMENT:
      return {
        ...state,
        addDocument: action.payload,
      };
    case GET_ALL_DOCUMENT:
      return {
        ...state,
        documentList: action.payload,
      };

    default:
      return state;
  }
};

export default documentReducer;
