import { Container } from "@mui/material";
import ResetPassword from "../../components/reset-password/ResetPassword";

const ResetPasswordPage = () => {
  return (
    <Container maxWidth="sm">
      <ResetPassword />
    </Container>
  );
};

export default ResetPasswordPage;
