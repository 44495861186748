import { Container } from '@mui/material';
import Home from '../../components/home/Home';

import React from 'react';

const HomePage = () => {
  return (
    <Container maxWidth={false}>
      <Home />
    </Container>
  );
};

export default HomePage;
