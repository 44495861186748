import { useDispatch, useSelector } from 'react-redux';
import { Box, List, ListItem, ListItemText, Typography } from '@mui/material';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import api from '../../utility/api';
import { useNavigate } from 'react-router-dom';
import { loginAction } from '../../redux/auth/authAction';

const SelectFirm = () => {
  const navigate = useNavigate();
  const { user, userProfiles } = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  return (
    <Box>
      <Typography variant="h5">Select Firm</Typography>
      <List>
        {userProfiles?.map((item) => {
          return (
            <ListItem
              sx={{ cursor: 'pointer' }}
              key={item.firm._id}
              onClick={async () => {
                const data = {
                  userId: user._id,
                  userProfileId: item._id,
                };
                try {
                  const response = await api.post(`auth/clients/users`, data);
                  if (response) {
                    localStorage.setItem('token', response.data.data.token);
                    dispatch(loginAction(response.data.data));
                    navigate('/home');
                  }
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              <AccountBalanceIcon />
              <ListItemText sx={{ ml: 2, fontWeight: 900 }}>
                {item?.firm?.name}
              </ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default SelectFirm;
