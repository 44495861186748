import Container from '@mui/material/Container';
import CaseList from '../../components/bills/Cases';
const CasesPage = () => {
  return (
    <Container maxWidth={false}>
      <CaseList />
    </Container>
  );
};

export default CasesPage;
