import styled from '@emotion/styled';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import UpdateEmail from '../update-email/UpdateEmail';
import UpdatePhone from '../update-phone/UpdatePhone';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const MyProfile = (props) => {
  let [userDetails, setUserDetails] = useState({});

  const user = useSelector((state) => state.auth.user);
  console.log(user);
  const dispatch = useDispatch();
  // const getSingleClientDetails = async (clientId, firmId) => {
  //   try {
  //     const response = await api.get(`/clients/${clientId}/firms/${firmId}`);
  //     setUserDetails(response.data.data.client);
  //   } catch (error) {
  //     dispatch(showSnackbarAction(error.response.data.message, 'error'));
  //   }
  // };
  // useEffect(() => {
  //   getSingleClientDetails(
  //     user?.userProfiles[0]?._id,
  //     user?.userProfiles[0]?.firm._id
  //   );
  // }, [user?.userProfiles[0]?._id]);

  // let initialValues = {
  //   firstName: userDetails?.firstName ? userDetails.firstName : '',
  //   lastName: userDetails?.lastName ? userDetails.lastName : '',
  // };

  const onSubmit = async (values, actions) => {
    const formData = {
      // practiceArea: values?.practiceArea,
      // caseName: values?.caseName,
      // caseNumber: values?.caseNumber,
      // caseStage: values?.caseStage,
      // office: values?.office,
      // description: values?.description,
      // statuteOfLimitation: values.statuteOfLimitation,
      // conflictCheck: true,
      // conflictCheckNote: values?.conflictCheckNote,
      // clientIds: clientsID,
      // companyIds: ['64590dc04871d63c44332e8d'],
      // attorneyId: user._id,
      // firmId: editData.firm,
    };

    try {
      // const response = await api.patch(`/cases/${editData._id}`, formData);
      // dispatch(showSnackbarAction(response.data.message, 'success'));
      // setShowEdit(!showEdit);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  // const formik = useFormik({
  //   initialValues: initialValues,
  //   onSubmit: onSubmit,
  // });

  return (
    <>
      <Typography
        marginBottom={2}
        paddingY={1}
        paddingX={2}
        sx={{ backgroundColor: 'lightgray' }}
      >
        Contact Info
      </Typography>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              label="First Name"
              type="text"
              fullWidth
              disabled
              value={user.userProfiles[0].firstName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              label="Middle"
              type="text"
              fullWidth
              disabled
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              value={user.userProfiles[0].middleName}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              label="Last Name"
              type="text"
              fullWidth
              variant="outlined"
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              disabled={true}
              value={user.userProfiles[0].lastName}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              label="City"
              type="text"
              disabled
              value={user?.user?.city}
              fullWidth
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              label="State"
              disabled
              value={user?.user?.state}
              type="text"
              fullWidth
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <TextField
              label="Mobile"
              type="text"
              disabled
              value={user?.user.mobile}
              fullWidth
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              }}
              variant="outlined"
            />
          </Grid>

          {/* <Grid item xs={12} sm={6} sx={{ width: '40%' }}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Enable Text Message Reminders"
              />
            </FormGroup>
          </Grid> */}
        </Grid>

        {/* <Button variant="contained" sx={{ marginTop: 2 }} size="large">
          Save Changes
        </Button> */}
      </form>
      {/* <Typography
        marginY={2}
        paddingY={1}
        paddingX={2}
        sx={{ backgroundColor: 'lightgray' }}
      >
        Change Email
      </Typography>
      <UpdateEmail />
      <Typography
        marginY={2}
        paddingY={1}
        paddingX={2}
        sx={{ backgroundColor: 'lightgray' }}
      >
        Change Phone
      </Typography>
      <UpdatePhone /> */}
    </>
  );
};

export default MyProfile;

{
  /* <Box sx={{ marginTop: 2 }}>
<Typography sx={{ marginTop: 4, color: "#03a9f4" }}>
  Curremt Email
</Typography>
<Typography sx={{ marginBottom: 4 }}>kbkhoth@gmail.com</Typography>
<form>
  <Grid container spacing={2}>
    <Grid item xs={12} sm={6} sx={{ width: "40%" }}>
      <TextField
        label="New Email*"
        type="text"
        fullWidth
        variant="outlined"
      />
    </Grid>
    <Box width="100%" />
    <Grid item xs={12} sm={6} sx={{ width: "40%" }}>
      <TextField
        label="Current Password"
        type="text"
        fullWidth
        variant="outlined"
      />
    </Grid>
    {/* <Box width="100%" /> */
}
//   </Grid>
//   <Button sx={{ marginTop: 4 }} variant="contained" color="primary">
//     UPDATE EMAIL
//   </Button>
// </form>
// </Box> */}
