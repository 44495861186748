import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import BasicTabs from '../Tab/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EventIcon from '@mui/icons-material/Event';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import CommentIcon from '@mui/icons-material/Comment';
import Loader from '../loader/Loader';
import { ArrowBack, Delete, Work } from '@mui/icons-material';

const TaskDetails = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  let [taskDetails, setTaskDetails] = useState({});
  let [taskComments, setTaskComments] = useState([]);
  let [comment, setComment] = useState('');

  const navigate = useNavigate();

  const getTaskDetail = async (firmId, taskId) => {
    try {
      setIsLoading(true);
      const response = await api.get(`tasks/firms/${firmId}/tasks/${taskId}`);

      setIsLoading(false);
      setTaskDetails(response.data.data.task);
      setTaskComments(response.data.data.alltaskComments);
    } catch (error) {
      setIsLoading(false);
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  useEffect(() => {
    getTaskDetail(user?.userProfiles[0]?.firm._id, state?.taskId);
  }, []);

  const changeTaskStatus = async (firmId, taskId, stats) => {
    try {
      let status = 'close';
      let message = 'Task completed successfully.';
      if (stats === 'close') {
        status = 'open';
        message = 'Task open successfully.';
      }
      delete taskDetails['status'];

      let formData = {
        status: status,
      };

      const response = await api.patch(
        `tasks/firms/${firmId}/tasks/${taskId}?clientPortal=true`,
        formData
      );
      setTaskDetails(response.data.data.singleTask);
      dispatch(showSnackbarAction(message, 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const commentSubmithandler = async (e) => {
    try {
      e.preventDefault();
      let formData = {
        text: comment,
        clientId: user.userProfiles[0]?._id,
      };
      const response = await api.post(
        `/tasks/${state.taskId}/firms/${user.userProfiles[0].firm?._id}/comments?clientId=${user.userProfiles[0]._id}`,
        formData
      );

      setComment('');
      setTaskComments(response.data.data.allTaskComments);
      // dispatch(showSnackbarAction('Comment added successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const commentDeletehandler = async (commentId) => {
    try {
      const response = await api.delete(
        `/tasks/${state?.taskId}/firms/${user.userProfiles[0]?.firm?._id}/comments/${commentId}?userId=${user.userProfiles[0]._id}`
      );

      setTaskComments(response.data.data.allTaskComments);
      dispatch(showSnackbarAction('Comment deleted successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  return (
    <Box sx={{ mt: 10 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Box
            sx={{
              bgcolor: '#F7F7F7',
              display: 'flex',
              borderBottom: '1px solid #cbcccf',
              padding: '1rem',
              boxShadow: '0 1px #efefef',
            }}
          >
            <ArrowBack
              sx={{
                cursor: 'pointer',
                alignItems: 'center',
                mr: 2,
                mt: 0.2,
              }}
              onClick={() => navigate(-1)}
            />
            <Typography
              variant="h5"
              sx={{
                alignItems: 'center',
                fontSize: '18px',
                fontWeight: 700,
              }}
            >
              {taskDetails?.name}
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <List>
              <ListItem disablePadding>
                {taskDetails?.status === 'open' ? (
                  <ListItemButton
                    onClick={() =>
                      changeTaskStatus(
                        user?.userProfiles[0]?.firm._id,
                        state.taskId,
                        taskDetails.status
                      )
                    }
                  >
                    <ListItemIcon>
                      <PanoramaFishEyeIcon sx={{ fontSize: '30px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '20px' }}
                      primary="Mark as Completed"
                    />
                  </ListItemButton>
                ) : (
                  <ListItemButton
                    sx={{ bgcolor: '#D4EDDA' }}
                    onClick={() =>
                      changeTaskStatus(
                        user?.userProfiles[0]?.firm._id,
                        state.taskId,
                        taskDetails.status
                      )
                    }
                  >
                    <ListItemIcon>
                      <OfflinePinIcon
                        sx={{
                          fontSize: '30px',
                          bgcolor: '47BA58',
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '20px' }}
                      primary="Completed!"
                    />
                  </ListItemButton>
                )}
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemText
                  primaryTypographyProps={{ fontSize: '20px' }}
                  primary={state?.caseName}
                />
              </ListItem> */}
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <Work sx={{ fontSize: '30px' }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '20px' }}
                    primary={state?.caseName}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <PriorityHighIcon sx={{ fontSize: '30px' }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: '20px' }}
                    primary={`Priority ${taskDetails?.priority}`}
                  />
                </ListItemButton>
              </ListItem>
              {taskDetails?.dueDate && (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <EventIcon sx={{ fontSize: '30px', color: '#DC3545' }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: '20px',
                        color: '#DC3545',
                      }}
                      primary={new Date(taskDetails?.dueDate).toDateString()}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {taskDetails?.description && (
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <DescriptionIcon sx={{ fontSize: '30px' }} />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{ fontSize: '20px' }}
                      primary={taskDetails?.description}
                    />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
            <Divider />
            <List>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <CommentIcon sx={{ fontSize: '30px' }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '20px',
                    }}
                    primary={`Comments (${taskComments.length})`}
                  />
                </ListItemButton>
              </ListItem>
            </List>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {taskComments?.length > 0 &&
                taskComments.map((comment) => {
                  return (
                    <>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 56, height: 56, bgcolor: '#0070BB' }}
                          >
                            {comment?.createdBy?.client
                              ? Array.from(
                                  comment.createdBy?.client?.firstName.toUpperCase()
                                )[0]
                              : Array.from(
                                  comment.createdBy?.attorney?.firstName.toUpperCase()
                                )[0]}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          sx={{
                            marginLeft: 4,
                          }}
                          primaryTypographyProps={{
                            fontSize: '20px',
                            color: 'black',
                          }}
                          primary={
                            comment?.createdBy?.client
                              ? comment.createdBy?.client?.firstName +
                                ' ' +
                                comment.createdBy?.client?.lastName
                              : comment.createdBy?.attorney?.firstName +
                                ' ' +
                                comment.createdBy?.attorney?.lastName
                          }
                          secondary={
                            <>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="#636C72"
                              >
                                {new Date(comment?.date).toLocaleString()}
                              </Typography>
                              <Box
                                sx={{ color: 'black', fontSize: '20px', mt: 1 }}
                              >
                                {comment.text}
                              </Box>
                              {comment?.createdBy?.client?._id ===
                                user.userProfiles[0]?._id && (
                                <Box
                                  sx={{
                                    float: 'right',
                                    cursor: 'pointer',
                                  }}
                                >
                                  <Delete
                                    onClick={() =>
                                      commentDeletehandler(comment?._id)
                                    }
                                  />
                                </Box>
                              )}
                            </>
                          }
                        />
                      </ListItem>
                      <Divider />
                    </>
                  );
                })}
            </List>
            <Box>
              <form onSubmit={commentSubmithandler}>
                <TextField
                  fullWidth
                  id="outlined-multiline-flexible"
                  label="Add Comment"
                  multiline
                  value={comment}
                  minRows={4}
                  maxRows={4}
                  onChange={(e) => setComment(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ bgcolor: '#0070BB', mt: 2, fontSize: '18px' }}
                  disabled={comment.trim() ? false : true}
                >
                  Post
                </Button>
              </form>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default TaskDetails;
