import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { loginAction } from '../../redux/auth/authAction';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';

const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;

const validationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .matches(
      passwordRegex,
      'Minimum 6 characters, at least 1 uppercase letter, at least 1 lowercase letter & at least 1 number required'
    )
    .required('Required'),

  confirmPassword: yup
    .string()
    .required('Required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const SetPassword = ({ step2, setStep }) => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { secret } = useParams();

  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const initialValues = {
    password: '',
    confirmPassword: '',
  };
  const onSubmit = async (values, actions) => {
    const formData = {
      verifyToken: step2?.token,
      password: values.password,
      confirmPassword: values.confirmPassword,
    };
    if (step2.userHasPassword === false) {
      try {
        const response = await api.post(
          `/auth/clients/signup/step3/password`,
          formData
        );
        console.log({ response });
        console.log('set password step', response.data.data);
        if (response) {
          dispatch(showSnackbarAction(response.data.message, 'success'));
          setStep('');
          // dispatch(loginAction(response.data.data));
          navigate('/register');
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(
          `/auth/clients/resetPassword/step3`,
          formData
        );

        if (response) {
          dispatch(showSnackbarAction(response.data.message, 'success'));

          navigate('/home');
        }
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={4}>
          <TextField
            id="password"
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            fullWidth
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
          <Button
            variant="contained"
            sx={{
              mt: 1,
              mr: 1,
              color: 'white',
              width: '100%',
              mt: 4,
              bgcolor: '#0070BB',
            }}
            type="submit"
          >
            Login
          </Button>
        </Stack>
      </form>
    </>
  );
};

export default SetPassword;
