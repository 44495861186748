import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Divider,
  TextField,
  ListItemText,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { Delete } from '@mui/icons-material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const [value, setValue] = React.useState(0);

  let [documentComments, setDocumentComments] = useState([]);
  let [comment, setComment] = useState('');

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    setDocumentComments(props.allComments);
  }, [props.allComments]);

  const commentDeletehandler = async (commentId) => {
    try {
      const response = await api.delete(
        `/cases/documents/${props.fileDetails._id}/firms/${user.userProfiles[0].firm._id}/comments/${commentId}?userId=${user.userProfiles[0]._id}`
      );

      setDocumentComments(response.data.data.allDocComments);
      dispatch(showSnackbarAction('Comment deleted successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  const commentSubmithandler = async (e) => {
    try {
      e.preventDefault();
      let formData = {
        text: comment,
        clientId: user.userProfiles[0]?._id,
      };
      const response = await api.post(
        `/cases/documents/${props.fileDetails._id}/firms/${user.userProfiles[0].firm._id}/comments?clientId=${user.userProfiles[0]._id}`,
        formData
      );

      setComment('');
      setDocumentComments(response.data.data.allDocumentComments);
      dispatch(showSnackbarAction('Comment added successfully.', 'success'));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={{ width: '50%' }} label="Info" {...a11yProps(0)} />
          <Tab
            sx={{ width: '50%' }}
            label={`Comments(${documentComments.length})`}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box>
          <Typography variant="p" sx={{ color: '#757575' }} component="p">
            File name
          </Typography>
          <Typography variant="p" component="p">
            {props.fileDetails?.document?.fileNameWithExtension}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="p" sx={{ color: '#757575' }} component="p">
            {props?.fileDetails?.case?.name}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="p" component="p">
            {'Latest:' + ' ' + props.fileDetails.name}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="p" sx={{ color: '#757575' }} component="p">
            created at
          </Typography>
          <Typography variant="p" component="p">
            {new Date(props.fileDetails.createdAt).toDateString()}
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="p" sx={{ color: '#757575' }} component="p">
            Description
          </Typography>
          <Typography variant="p" component="p">
            {props.fileDetails.description}
          </Typography>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {documentComments?.length > 0 &&
            documentComments.map((comment) => {
              return (
                <>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        sx={{ width: 56, height: 56, bgcolor: '#0070BB' }}
                      >
                        {
                          Array.from(
                            comment?.createdBy?.attorney
                              ? comment?.createdBy?.attorney?.firstName.toUpperCase()
                              : comment?.createdBy?.client?.firstName.toUpperCase()
                          )[0]
                        }
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{
                        marginLeft: 4,
                      }}
                      primaryTypographyProps={{
                        fontSize: '20px',
                        color: 'black',
                      }}
                      primary={
                        comment?.createdBy?.client
                          ? comment.createdBy?.client?.firstName +
                            ' ' +
                            ` ${comment.createdBy?.client?.lastName} (Client)`
                          : comment.createdBy?.attorney?.firstName +
                            ' ' +
                            `${comment.createdBy?.attorney?.lastName} (Attorney)`
                      }
                      secondary={
                        <>
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="#636C72"
                          >
                            {new Date(comment?.date).toLocaleString()}
                          </Typography>

                          <Typography
                            sx={{
                              color: 'black',
                              fontSize: '15px',
                              mt: 1,
                            }}
                          >
                            {comment.text}
                          </Typography>
                          {comment?.createdBy?.client?._id ===
                            user.userProfiles[0]?._id && (
                            <Box sx={{ float: 'right', cursor: 'pointer' }}>
                              <Delete
                                onClick={() =>
                                  commentDeletehandler(comment?._id)
                                }
                              />
                            </Box>
                          )}
                        </>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              );
            })}
        </List>
        <Box>
          <form onSubmit={commentSubmithandler}>
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Add Comment"
              multiline
              value={comment}
              minRows={4}
              maxRows={4}
              onChange={(e) => setComment(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{ bgcolor: '#0070BB', mt: 2, fontSize: '18px' }}
              disabled={comment.trim() ? false : true}
            >
              Post
            </Button>
          </form>
        </Box>
      </TabPanel>
    </Box>
  );
}
