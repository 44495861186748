import Container from '@mui/material/Container';
import Documents from '../../components/documents/Documents';
const DocumentsPage = () => {
  return (
    <Container maxWidth={false}>
      <Documents />
    </Container>
  );
};

export default DocumentsPage;
