import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import EmailOrMobile from './EmailOrMobile';
import api from '../../utility/api';
import MobileOtp from './MobileOtp';
import SetPassword from './SetPassword';
import EnterPassword from './EnterPassword';

const validationSchema = yup.object().shape({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),

  mobile: yup
    .string('Enter  mobile')
    .required('Mobile is required')
    .test(
      'maxLenght',
      'Max 10',
      (val) => !isNaN(val) && `${val}`.length === 10
    ),
});

export default function VerticalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [emailOrPhoneVal, setEmailOrPhoneVal] = useState('');
  const [isPhone, setIsPhone] = useState(false);
  const [step1, setStep1] = useState('');
  const [step2, setStep2] = useState('');

  const navigate = useNavigate();

  const initialValues = {
    emailOrPhone: emailOrPhoneVal,
    countryCode: '',
    otp: '',
    password: '',
    confirmPassword: '',
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      email: !isPhone ? emailOrPhoneVal : '',
      mobile: isPhone ? emailOrPhoneVal : '',
      countryCode: values.countryCode.value,
    };

    if (!isPhone) {
      delete formData.countryCode;
      delete formData.mobile;
    }

    if (isPhone) {
      delete formData.email;
    }
    if (activeStep === 1) {
      try {
        const response = await api.post(`/auth/clients/login/step1`, formData);
        setStep1(response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    }
    if (activeStep === 2 && step1?.userRegistrationStatus === false) {
      const formData = {
        _id: step1 && step1._id,
        otp: values.otp,
      };
      try {
        const response = await api.post(
          `auth/clients/signup/step2/otp/verify`,
          formData
        );
        console.log('response', response.data.data);
        setStep2(response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    }

    if (step1?.userRegistrationStatus) {
      const formData = {
        password: values.password,
        userId: step1.userId,
      };

      try {
        const response = await api.post(`/auth/clients/password`, formData);
        console.log('resp step 4', response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    }

    if (activeStep === 3 && step2?.userHasPasswrd === false) {
      const formData = {
        verifyToken: step2?.token,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      try {
        const response = await api.post(
          `/auth/clients/signup/step3/password`,
          formData
        );
        console.log('response', response.data.data);
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const steps = [
    {
      label: 'Enter Email Or Mobile',
      description: (
        <EmailOrMobile
          formik={formik}
          isPhone={isPhone}
          setIsPhone={setIsPhone}
          emailOrPhoneVal={emailOrPhoneVal}
          setEmailOrPhoneVal={setEmailOrPhoneVal}
        />
      ),
    },
  ];

  step1.userRegistrationStatus &&
    steps.push({
      label: 'password',
      description: <EnterPassword formik={formik} />,
    });

  if (!step1.userRegistrationStatus) {
    steps.push({
      label: 'otp',
      description: <MobileOtp formik={formik} />,
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
      }}
    >
      <Typography variant="h3" textAlign="center">
        Welcome to Attorney
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Paper sx={{ width: '600px', p: 6, mt: 6 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  optional={
                    index === steps.length ? (
                      <Typography variant="caption">Last step</Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <StepContent>
                  <Typography>{step.description}</Typography>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          mt: 1,
                          mr: 1,
                          color: 'white',
                          bgColor: '#0070BB',
                        }}
                        type="submit"
                      >
                        {index === steps.length - 1 ? 'submit' : 'Continue'}
                      </Button>
                      {index <= 1 ? null : (
                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                          Back
                        </Button>
                      )}
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </form>
    </Box>
  );
}
