import { GET_ALL_RECENTACTIVITY } from './recentActivityTypes';

const INITIAL_STATE = {
  recentActivityList: [],
};

const recentActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_RECENTACTIVITY:
      return {
        ...state,
        recentActivityList: action.payload,
      };

    default:
      return state;
  }
};

export default recentActivityReducer;
