import { Container } from '@mui/material';
import MyProfile from '../../components/profile/MyProfile';

const ProfilePage = () => {
  return (
    <Container maxWidth={false}>
      <MyProfile />
    </Container>
  );
};

export default ProfilePage;
