import { useFormik } from 'formik';
import * as yup from 'yup';

import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import api from '../../utility/api';
import { useEffect, useState } from 'react';
import MobileOtp from '../SignUp/MobileOtp';
import { useDispatch } from 'react-redux';
import { showSnackbarAction } from '../../redux/alert/alertAction';

var reg = /^[0-9]{1,10}$/;

const ForgotPassword = () => {
  const [step1, setStep1] = useState('');
  const [countryCodes, setCountryCodes] = useState([]);
  const [emailOrPhoneVal, setEmailOrPhoneVal] = useState('');
  const [isPhone, setIsPhone] = useState(false);

  // const initialValues = {
  //   email: "",
  // };

  const dispatch = useDispatch();

  const initialValues = {
    emailOrPhone: emailOrPhoneVal,
    countryCode: '',
  };
  const onSubmit = async (values, actions) => {
    // const formData = {
    //   email: values.email,
    // };
    const formData = {
      email: !isPhone ? emailOrPhoneVal : '',
      mobile: isPhone ? emailOrPhoneVal : '',
      countryCode: values.countryCode.value,
    };

    if (!isPhone) {
      delete formData.countryCode;
      delete formData.mobile;
    }

    if (isPhone) {
      delete formData.email;
    }

    try {
      const response = await api.post(
        `auth/clients/resetPassword/step1`,
        formData
      );
      setStep1(response.data.data);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const getCountryCode = async () => {
    try {
      const response = await api.get(`/locations/countryCodes`);
      setCountryCodes(response.data?.data.countries);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  return (
    <Paper sx={{ padding: 4, marginTop: 20 }}>
      {step1 === '' ? (
        <>
          <Typography variant="h5">Forgot Password? </Typography>
          <Typography sx={{ mt: 1, mb: 4 }}>
            Don't worry! Happens to the best of us.
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                id="emailOrPhone"
                name="emailOrPhone"
                label="Email or Mobile"
                variant="outlined"
                required={true}
                value={emailOrPhoneVal}
                onChange={(e) => {
                  setEmailOrPhoneVal(e.target.value);
                  setIsPhone(reg.test(e.target.value));
                }}
              />
              {isPhone && emailOrPhoneVal.length > 10 ? (
                <Typography color="red">Mobile must be 10 digits</Typography>
              ) : null}

              {isPhone && (
                <Autocomplete
                  value={formik.values?.countryCode}
                  id="countryCode"
                  name="countryCode"
                  options={countryCodes}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue('countryCode', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="CountryCode"
                      error={
                        formik.touched.countryCode &&
                        Boolean(formik.errors.countryCode)
                      }
                      helperText={
                        formik.touched.countryCode && formik.errors.countryCode
                      }
                    />
                  )}
                />
              )}
              <Button
                variant="contained"
                sx={{ mt: 1, mr: 1, color: 'white', bgcolor: '#0070BB' }}
                type="submit"
              >
                Send
              </Button>
            </Stack>
          </form>
        </>
      ) : (
        <MobileOtp step1={step1} />
      )}
    </Paper>
  );
};

export default ForgotPassword;
