import { Box, Container } from '@mui/material';
import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import { Outlet } from 'react-router-dom';

const DashboardPage = () => {
  return (
    <Container maxWidth={false}>
      {/** AdminAppbar has fixed position to the top 0 and right 0 and left auto */}

      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>
        {/** toolbar here gives empty height and content down bdelow appbar  */}
        <Navbar />
        <Box py={1}>
          <Outlet />
        </Box>
      </Box>
    </Container>
  );
};

export default DashboardPage;
