import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { useEffect, useState } from 'react';
import { loadDocumentListAction } from '../../redux/document/documentAction';
import { loadRecentActivityListAction } from '../../redux/recentActivity/recentActivityAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
  height: '100%',
  display: 'block',
};

const AddDocument = ({ showModal, handleClose, case_id }) => {
  const [cases, setCases] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [caseId, setCaseId] = useState();

  const documentValidationSchema = yup.object().shape({
    ...(cases?.length > 1 &&
      !case_id && {
        caseLink: yup.object().shape({
          label: yup.string().required('Required'),
          value: yup.string().required('Required'),
        }),
      }),
    docName: yup.string('Enter doc name').required('Required'),
    document: yup.array().min(1).required('Required'),
  });

  const getAllCases = async (clientID, firmID) => {
    try {
      const response = await api.get(
        `clients/${clientID}/cases/firms/${firmID[0]}       `
      );

      setCases(response.data.data.case);
    } catch (error) {
      console.log('error', error);
    }
  };
  const initialValues = {
    docName: '',
    description: '',
    document: [],
    caseLink: null,
  };

  const myDropZone = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      formik.setFieldValue('document', acceptedFiles);
    },
  });

  const { getRootProps, getInputProps } = myDropZone;

  let firm = user?.userProfiles?.map((item) => item.firm._id);

  const onSubmit = async (values, actions) => {
    const formData = {
      docName: values.docName,
      description: values.description,
      uploadedBy: user?.userProfiles[0]?._id,
      type: 'case',
      document: values.document,
    };
    console.log({ formData });
    async function apiCall() {
      try {
        let response;
        // if (editMode) {
        //   response = await api.patch(
        //     `attendees/${user?._id}/credits/externals/${editData?._id}`,
        //     formData
        //   );
        // } else {
        let url = '';
        if (case_id) {
          url = `/clients/firms/${firm}/cases/${case_id}`;
        } else {
          url = `/clients/firms/${firm}/cases/${
            cases.length == 1 ? cases[0]._id : caseId.value
          }`;
        }
        console.log(formik.values.caseLink);
        console.log({ url });
        response = await api.post(url, formData);

        dispatch(loadDocumentListAction(response.data.data.allDocuments));
        dispatch(
          loadRecentActivityListAction(response.data.data.recentDocuments)
        );
        handleClose();

        // dispatch(showSnackbarAction(response.data.message, "success"));
        // setUploadDialog();
        // }
        // if (response) {
        //   dispatch(
        //     loadUserExternalCreditsAction(response.data.data.externalCredits)
        //   );
        //   if (editMode) {
        //     setEditMode(false);
        //   } else {
        //     setShowExternalCreditForm(false);
        //   }
        //   dispatch(alertAction(response.data.message, "success"));
        // }
      } catch (err) {
        dispatch(showSnackbarAction(err.response.data.message, 'error'));
      }
    }
    if (formik.values.document?.length > 0) {
      let newFiles = [];
      formik.values.document?.map((file) =>
        file.key ? null : newFiles.push(file)
      );
      if (newFiles.length > 0) {
        const fileDataObj = new FormData();
        newFiles.forEach((file) => fileDataObj.append('file', file));

        if (fileDataObj.has('file')) {
          try {
            const fileResponse = await axios.post(
              'https://file.starlawyer.in/api/afsgdfgsdf56754hgfjhgj',
              fileDataObj
            );
            if (fileResponse) {
              formData.document = fileResponse.data.data;
              formData.document[0].name = formik.values.document[0]?.name;
            }
          } catch (err) {
            dispatch(showSnackbarAction(err.response.data.message, 'error'));
            return;
          }
        }
      }
      await apiCall();
    } else {
      // await apiCall();
    }
  };

  let caseListOptions =
    cases?.length > 0
      ? cases?.map((item) => {
          let Obj = {
            label: item.caseName,
            value: item._id,
          };
          return Obj;
        })
      : '';
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
    validationSchema: documentValidationSchema,
    enableReinitialize: true,
  });

  const userID = user.userProfiles.map((item) => item._id);

  useEffect(() => {
    getAllCases(user?.userProfiles[0]?._id, firm);
  }, []);

  return (
    <Modal
      open={cases.length > 0 ? showModal : false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5">New Document</Typography>
          <Typography onClick={() => handleClose()}>
            <CloseIcon />
          </Typography>
        </Box>
        <Divider />
        <Typography my={2}>
          Note: All documents will be uploaded to the main documents list, not
          into a folder
        </Typography>
        <Typography my={1}>All feilds marked * are required</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {cases?.length > 1 && !case_id && (
              <Autocomplete
                id="caseLink"
                name="caseLink"
                options={caseListOptions || []}
                getOptionLabel={(option) => option?.label}
                value={formik.values?.caseLink}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue('caseLink', newValue);
                  setCaseId(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Case Link"
                    error={
                      formik.touched.caseLink && Boolean(formik.errors.caseLink)
                    }
                    helperText={
                      formik.touched.caseLink && formik.errors.caseLink
                    }
                  />
                )}
              />
            )}
            <TextField
              id="docName"
              name="docName"
              label="Ducument name*"
              variant="standard"
              fullWidth
              variant="outlined"
              value={formik.values.docName}
              onChange={formik.handleChange}
              error={formik.touched.docName && Boolean(formik.errors.docName)}
              helperText={formik.touched.docName && formik.errors.docName}
              sx={{ marginTop: '24px' }}
            />
            <TextField
              id="description"
              name="description"
              label="Description"
              variant="outlined"
              fullWidth
              value={formik.values.description}
              onChange={formik.handleChange}
              sx={{ marginTop: '24px' }}
            />

            <Typography my={2} color="#0070BB">
              Upload File*
            </Typography>
            {formik.values.document?.length > 0 ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 60,
                    border: '1px dashed grey',
                    borderRadius: 5,
                    px: 5,
                    bgcolor: '#f5f5f5',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      width: '100%',
                      mr: '2.5rem',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography mr={3}>
                      {formik.values.document[0]?.name || 'document'}
                    </Typography>
                  </Box>
                  <i
                    onClick={() => {
                      if (formik.values.document[0]?.key) {
                        formik.setFieldValue(
                          'deteledCertificate',
                          formik.values.document[0]?.key
                        );
                      }
                      formik.setFieldValue('document', []);
                    }}
                  >
                    <CloseIcon
                      sx={{
                        position: 'absolute',
                        right: '1.4rem',
                        top: '50%',
                        transform: 'translate(0, -50%)',
                        transformOrigin: 'left top',
                      }}
                    />
                  </i>
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: 60,
                  border: '1px dashed grey',
                  borderRadius: 5,
                  px: 5,
                }}
              >
                <div {...getRootProps()}>
                  <div>
                    <Typography>Click & browse to upload document</Typography>
                  </div>
                  <input {...getInputProps()} />
                </div>
              </Box>
            )}
            <Box>
              {formik.touched.document && formik.errors.document ? (
                <FormHelperText
                  sx={{
                    color: '#bf3333',
                    marginLeft: '16px !important',
                  }}
                >
                  {formik.touched.document && formik.errors.document}
                </FormHelperText>
              ) : null}
            </Box>
          </Box>
          <Box sx={{ marginTop: 3 }}>
            <Button
              sx={{
                background: '#0070BB',
                mr: 3,
                color: 'white',
                ':hover': {
                  background: '#0275D8',
                },
              }}
              type="submit"
            >
              ADD DOCUMENT
            </Button>
            <Button
              onClick={() => handleClose()}
              sx={{ fontSize: '15px', color: '#0070BB' }}
            >
              CANCEL
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default AddDocument;
