import {
  AUTH_ERROR,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  USER_LOADED,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  LOADUSERPROFILE,
} from './authTypes';
import axios from 'axios';
import api from '../../utility/api';
import setAuthToken from '../../utility/setAuthToken';

// loaduser

export const loadUserAction = () => async (dispatch) => {
  try {
    // const response = await axios({
    //   method: 'GET',
    //   withCredentials: true,
    //   url: 'http://api.legalpractice.in/api/auth/clients/user',
    // });
    const response = await api.get('/auth/clients/user');

    dispatch({
      type: USER_LOADED,
      payload: response?.data?.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// register;

export const registerAction = (formData, history) => async (dispatch) => {
  try {
    // const response = await axios({
    //   method: "POST",
    //   data: formData,
    //   withCredentials: true,
    //   url: "http://localhost:5000/api/register",
    // });
    const response = await api.post('register', formData);
    dispatch({
      type: REGISTER_SUCCESS,
    });
    const { msg } = response.data;
    // dispatch(messageAction(msg));
    //redirecting to message component
    // history.push('/message');
  } catch (err) {
    const { errors } = err.response.data;
    if (errors) {
      errors.forEach((error) => console.log('error', error));
    }
    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// login

export const loginAction = (userData) => async (dispatch) => {
  setAuthToken(localStorage.token);
  dispatch({
    type: LOGIN_SUCCESS,
    payload: userData,
  });
};

export const LoadUserProfile = (userData) => async (dispatch) => {
  setAuthToken(localStorage.token);
  dispatch({
    type: LOADUSERPROFILE,
    payload: userData,
  });
};

// logout

export const logoutAction = () => async (dispatch) => {
  // dispatch({
  //   type: REMOVE_PROFILE,
  // });
  localStorage.removeItem('token');
  dispatch({
    type: LOGOUT,
  });
};
