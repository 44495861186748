import {
  Box,
  Button,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentList from './DocumentList';

import CloseIcon from '@mui/icons-material/Close';
import AddDocument from './AddDocument';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

let documentList = [
  {
    _id: 1,
    name: 'draft',
    type: 'folder',
    updatedAt: '17 april 2023',
  },
  {
    _id: 2,
    name: 'private',
    type: 'folder',
    updatedAt: '17 april 2023',
  },
  {
    _id: 3,
    name: 'wasim.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 4,
    name: 'vinaya.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 5,
    name: 'kb.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 6,
    name: 'doc.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 7,
    name: 'docccccccccccccccccccccccccccccccccccccccc.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 8,
    name: 'marksheet.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 9,
    name: 'aadhard.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
  {
    _id: 10,
    name: 'license.pdf',
    type: 'file',
    updatedAt: '17 april 2023',
  },
];

const Documents = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(!showModal);
  };
  const dispatch = useDispatch();
  let [cases, setCases] = useState();
  const user = useSelector((state) => state.auth.user);
  const getAllCases = async (userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/cases/firms/${firmId}`
      );
      setCases(response.data.data.case);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };

  useEffect(() => {
    getAllCases(user?.userProfiles[0]?._id, user?.userProfiles[0]?.firm?._id);
  }, [user?.userProfiles[0]?._id]);
  return (
    // <Box sx={{ mt: 2 }}>
    <>
      <DocumentList data={cases} />
      {showModal && (
        <AddDocument showModal={showModal} handleClose={handleClose} />
      )}
    </>
    // </Box>
  );
};

export default Documents;
