import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Box, Button, TextField } from '@mui/material';
import api from '../../utility/api';
import SetPassword from './SetPassword';
import { useNavigate } from 'react-router-dom';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { useDispatch } from 'react-redux';
import EnterPassword from './EnterPassword';

const MobileOtp = ({ step1, setStep }) => {
  const [step2, setStep2] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    otp: '',
  };

  const onSubmit = async (values, actions) => {
    const formData = {
      _id: step1._id,
      otp: values.otp,
    };
    if (step1.userRegistrationStatus === false) {
      try {
        const response = await api.post(
          `auth/clients/signup/step2/otp/verify`,
          formData
        );
        console.log('mobile otp verify step', response.data.data);
        setStep2(response.data.data);
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    } else {
      try {
        const response = await api.post(
          `/auth/clients/resetPassword/step2`,
          formData
        );
        setStep2(response.data.data);
      } catch (error) {
        dispatch(showSnackbarAction(error.response.data.message, 'error'));
      }
    }
  };
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <Box>
      {step2.length === 0 ? (
        <form onSubmit={formik.handleSubmit}>
          <Box>
            <TextField
              id="otp"
              name="otp"
              label="Enter OTP"
              variant="outlined"
              fullWidth
              value={formik.values.otp}
              onChange={formik.handleChange}
            />
            <Button
              variant="contained"
              sx={{
                mt: 1,
                mr: 1,
                color: 'white',
                width: '100%',
                mt: 4,
                bgColor: '#0070BB',
              }}
              type="submit"
            >
              Continue
            </Button>
          </Box>
        </form>
      ) : step2.userHasPassword ? (
        <EnterPassword step1={step1} step2={step2} />
      ) : (
        <SetPassword step2={step2} setStep={setStep} />
      )}
    </Box>
  );
};

export default MobileOtp;
