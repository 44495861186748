import { Container } from "@mui/material";
import SignUp from "../../components/SignUp/SignUp";
import EmailOrMobile from "../../components/SignUp/EmailOrMobile";

const RegisterPage = () => {
  return (
    <Container maxWidth="sm">
      <EmailOrMobile />
    </Container>
  );
};

export default RegisterPage;
