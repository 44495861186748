import Container from '@mui/material/Container';
import BillsList from '../../components/bills/Bills';
const BillsPage = () => {
  return (
    <Container maxWidth={false}>
      <BillsList />
    </Container>
  );
};

export default BillsPage;
