import { Container } from "@mui/material";
import ForgotPassword from "../../components/forgot-password/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <Container maxWidth="sm">
      <ForgotPassword />
    </Container>
  );
};

export default ForgotPasswordPage;
