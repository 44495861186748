import Container from '@mui/material/Container';
import Documents from '../../components/documents/Documents';
import CaseDocuments from '../../components/documents/CaseDocuments';
const CaseDocumentsPage = () => {
  return (
    <Container maxWidth={false}>
      <CaseDocuments />
    </Container>
  );
};

export default CaseDocumentsPage;
