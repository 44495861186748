import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import authReducer from '../redux/auth/authReducer';
import alertReducer from '../redux/alert/alertReducer';
import documentReducer from './document/documentReducer';
import recentActivityReducer from './recentActivity/recentActivityReducer';
import taskReducer from './task/taskReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile'],
};

const rootReducer = combineReducers({
  auth: authReducer,
  snackbar: alertReducer,
  document: documentReducer,
  recentActivity: recentActivityReducer,
  tasks: taskReducer,
});

export default persistReducer(persistConfig, rootReducer);
