import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useTheme,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Menu,
} from '@mui/material';
import DrawerComp from '../drawerComp/DrawerComp';

import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';
import styled from '@emotion/styled';
import { logoutAction } from '../../redux/auth/authAction';

const AppNav = styled(AppBar)({
  backgroundColor: '#212121',
});

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isMatches = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const location = useLocation();
  return (
    <AppNav position="fixed">
      <Toolbar sx={{ bgcolor: '#2C2C2C' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            cursor: 'pointer',
          }}
          marginRight="auto"
        >
          {isMatches ? (
            <DrawerComp />
          ) : (
            <>
              <NavLink
                to="/home"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? '#FFFFFF' : '#a0a0a0',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h6" letterSpacing={0.5}>
                  HOME
                </Typography>
              </NavLink>
              <NavLink
                to="/documents"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? '#FFFFFF' : '#a0a0a0',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h6" letterSpacing={0.5}>
                  DOCUMENTS
                </Typography>
              </NavLink>
              <NavLink
                to="/tasks"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? '#FFFFFF' : '#a0a0a0',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography marginRight={4} variant="h6" letterSpacing={0.5}>
                  TASKS
                </Typography>
              </NavLink>
              <NavLink
                to="/cases"
                style={({ isActive, isPending }) => {
                  return {
                    fontWeight: isActive ? 'bold' : '',
                    color: isActive ? '#FFFFFF' : '#a0a0a0',
                    textDecoration: 'none',
                  };
                }}
              >
                <Typography
                  variant="h6"
                  letterSpacing={0.5}
                  onClick={() => navigate('/cases')}
                  marginRight={4}
                >
                  BILLING
                </Typography>
              </NavLink>
            </>
          )}
        </Box>
        <Typography variant="h6" sx={{ mr: 2 }} letterSpacing={0.5}>
          {user.userProfiles?.[0]?.firm?.name}
        </Typography>

        <Typography
          variant="h6"
          sx={{ cursor: 'pointer', color: open ? '#FFFFFF' : '#a0a0a0' }}
          letterSpacing={0.5}
          onClick={handleClick}
        >
          MY ACCOUNT
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              bgcolor: 'white',
              color: 'black',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem
            button
            onClick={() => {
              navigate('/settings');
            }}
          >
            <ListItemIcon>
              <SettingsIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              navigate('/terms');
            }}
          >
            <ListItemIcon>
              <GavelIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Terms
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              navigate('/privacy-policy');
            }}
          >
            <ListItemIcon>
              <PrivacyTipIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Privacy Policy
          </MenuItem>
          <MenuItem
            button
            onClick={() => {
              dispatch(logoutAction());
              navigate('/register');
            }}
          >
            <ListItemIcon>
              <LogoutIcon style={{ color: 'black' }} fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppNav>
  );
};

export default Navbar;
