import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import BasicTabs from '../Tab/Tab';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import PreviewIcon from '@mui/icons-material/Preview';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DocumentDetails = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  let [documentDetails, setDocumentDetails] = useState({});
  let [comments, setComments] = useState({});

  const getDocumentDetail = async (
    userProfileId,
    documentId,
    firmId,
    caseId
  ) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/firms/${firmId}/cases/${caseId}/documents/${documentId}?type=${
          state?.type ? 'bill' : ''
        }`
      );

      setDocumentDetails(response.data.data.document);
      setComments(response.data.data.allComents);
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  useEffect(() => {
    getDocumentDetail(
      user.userProfiles[0]?._id,
      state.documentId,
      user.userProfiles[0]?.firm?._id,
      state.caseId
    );
  }, []);

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  console.log({ documentDetails });

  return (
    <Card>
      <CardHeader
        title={
          <ArrowBackIcon
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate(-1)}
          />
        }
        sx={{ borderBottom: '2px solid #eeeeee' }}
      />
      <CardContent sx={{ bgcolor: '#f5f5f5' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            color: '#1976d2',
            alignItems: 'center',
          }}
        >
          <DescriptionIcon sx={{ color: '#bdbdbd', fontSize: 150 }} />
          <Typography
            variant="h5"
            component="h5"
            sx={{ mt: 1, color: '0070BB' }}
          >
            {documentDetails?.name}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Box>
              <Button
                href={documentDetails?.document?.Location}
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  mt: 1,
                  mr: 2,
                  bgcolor: '#0070BB',
                }}
                startIcon={<DownloadIcon />}
              >
                Download
              </Button>
              <Button
                onClick={() =>
                  openInNewTab(documentDetails?.document?.Location)
                }
                variant="contained"
                sx={{
                  textTransform: 'capitalize',
                  mt: 1,
                  bgcolor: '#0070BB',
                }}
                startIcon={<PreviewIcon />}
              >
                Preview
              </Button>
            </Box>
          </Stack>
        </Box>
      </CardContent>
      <CardActions>
        <BasicTabs fileDetails={documentDetails} allComments={comments} />
      </CardActions>
    </Card>
  );
};

export default DocumentDetails;
