import { Stack } from '@mui/material';
import Terms from '../../components/terms-privacy-policy/Terms';
import { Box, Container } from '@mui/system';

const TermsPage = () => {
  return (
    // <Stack sx={{ textAlign: "center", my: 15, mx: 80 }}>
    //   <Terms />
    // </Stack>
    <Container maxWidth={false}>
      <Box sx={{ marginTop: 10, p: 4 }}>
        <Terms />
      </Box>
    </Container>
  );
};

export default TermsPage;
