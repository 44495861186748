import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import { showSnackbarAction } from '../../redux/alert/alertAction';
import { loadTaskListAction } from '../../redux/task/taskAction';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TaskTab(props) {
  const user = useSelector((state) => state.auth.user);
  const taskList = useSelector((state) => state?.tasks?.taskList);

  const dispatch = useDispatch();
  const getTasks = async (userProfileId, firmId) => {
    try {
      const response = await api.get(
        `/clients/${userProfileId}/firms/${firmId}/tasks`
      );

      dispatch(loadTaskListAction(response.data.data.tasks));
    } catch (error) {
      dispatch(showSnackbarAction(error.response.data.message, 'error'));
    }
  };
  useEffect(() => {
    getTasks(user.userProfiles[0]?._id, user.userProfiles[0]?.firm?._id);
  }, [user.userProfiles[0]?._id]);

  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 8 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-around',
          bgcolor: '#F7F7F7',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab sx={{ width: '50%' }} label="Open" {...a11yProps(0)} />
          <Tab sx={{ width: '50%' }} label="Completed" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <List sx={{ mt: -3 }}>
          {taskList &&
            taskList?.map((d, i) => {
              if (d?.status === 'open')
                return (
                  <Box key={d._id}>
                    <ListItem
                      // secondaryAction={
                      //   <Typography
                      //     sx={{ display: { xs: 'none', sm: 'block' } }}
                      //     variant="p"
                      //   >
                      //     {`Case (${d?.case?.caseName})`}
                      //   </Typography>
                      // }
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() =>
                          navigate('/tasks/task-details', {
                            state: {
                              taskId: d._id,
                              caseName: d?.case?.caseName,
                            },
                          })
                        }
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '18px',
                            color: '#212529',
                          }}
                          primary={`${d.name} (${d.case?.caseName})`}
                          sx={{ overflow: 'hidden' }}
                          // secondary={d.document.fileNameWithExtension}
                        />
                      </ListItemButton>
                    </ListItem>
                    {i + 1 == taskList.length ? null : <Divider />}
                  </Box>
                );
            })}
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <List sx={{ mt: -3 }}>
          {taskList &&
            taskList?.map((d, i) => {
              if (d?.status === 'close')
                return (
                  <Box key={d._id}>
                    <ListItem
                      // secondaryAction={
                      //   <Typography
                      //     sx={{ display: { xs: 'none', sm: 'block' } }}
                      //     variant="p"
                      //   >
                      //     {`Case (${d?.case?.caseName})`}
                      //   </Typography>
                      // }
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() =>
                          navigate('/tasks/task-details', {
                            state: {
                              taskId: d._id,
                              caseName: d?.case?.caseName,
                            },
                          })
                        }
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '18px',
                            color: '#212529',
                          }}
                          primary={`${d.name} (${d.case?.caseName})`}
                          sx={{ overflow: 'hidden' }}
                          // secondary={d.document.fileNameWithExtension}
                        />
                      </ListItemButton>
                    </ListItem>
                    {i + 1 == taskList.length ? null : <Divider />}
                  </Box>
                );
            })}
        </List>
      </TabPanel>
    </Box>
  );
}

export default TaskTab;
