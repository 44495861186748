import { GET_ALL_TASK } from './taskTypes';

const INITIAL_STATE = {
  taskList: [],
};

const taskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_TASK:
      return {
        ...state,
        taskList: action.payload,
      };

    default:
      return state;
  }
};

export default taskReducer;
