import { useState } from 'react';
import { Box, Paper, Stack } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../Tab/TabsPanel';
import Profile from '../profile/Profile';
import ChangePasswordForm from './ChangePassword';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Documents = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Paper sx={{ marginTop: 15 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        TabIndicatorProps={{
          sx: { backgroundColor: 'black' },
        }}
      >
        <Tab label="My Profile" {...a11yProps(0)} style={{ width: '800px' }} />
        <Tab
          label="My prefrences"
          {...a11yProps(1)}
          style={{ width: '800px' }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePasswordForm />
      </TabPanel>
    </Paper>
  );
};

export default Documents;
