import { GET_ALL_DOCUMENT, ADD_DOCUMENT } from './documentTypes';

export const loadDocumentListAction = (data) => (dispatch) => {
  dispatch({
    type: GET_ALL_DOCUMENT,
    payload: data,
  });
};

export const loadAddDocumentAction = (data) => (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT,
    payload: data,
  });
};
